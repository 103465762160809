import { KeyValue } from '@angular/common';

export declare interface IdName<T, S = number> {
  id: S;
  name: T;
  selected?: boolean;
}

// eslint-disable-next-line
// @ts-ignore: noImplicitAny
export function idNames(T): Array<IdName<any>> {
  return Object.keys(T).filter(type => typeof T[type] === 'string').map(key => ({name: T[key], id: +key}));
}

// eslint-disable-next-line
// @ts-ignore: noImplicitAny
export function keyValues(T): Array<KeyValue<number, string>> {
  return Object.keys(T).filter(type => !isNaN(type as any) && type !== 'values').map(key => ({key: +key, value: T[key]}));
}
// eslint-disable-next-line
// @ts-ignore: noImplicitAny
export function idNameString(T): Array<IdName<string, string>> {
  return Object.keys(T).filter(type => typeof T[type] === 'string').map(key => ({ name: T[key], id: key }));
}
