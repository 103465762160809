import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationBaseRequest, RegistrationResponse } from '@bs24/core/models/registration';
import { SessionStorage } from '@bs24/universal/universal.providers';
import { WINDOW } from '@bs24/universal/window.service';
import { BehaviorSubject, interval, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Settings } from '../models/app-settings';
import { EnvConfig } from '../models/environment-config';
import { ILoginCredentials, ILoginResponse } from '../models/login-credentials';
import { AccountTypeId, CheckToken, IMe } from '../models/me';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  readonly url = this.config.api.endpoint;

  accountLogged$ = new BehaviorSubject<IMe | undefined>(undefined);
  currentProfile: WritableSignal<IMe | undefined> = signal(undefined);
  settings!: Partial<Settings>;
  private _checkToken!: Subscription;

  constructor(private config: EnvConfig, private sessionStorage: SessionStorage, @Inject(WINDOW) private window: any,
              private http: HttpClient, private router: Router, settingsService: SettingsService) {
    /*settingsService.settings$.subscribe({
      next: (settings) => this.settings = settings
    });*/
    this.settings = settingsService.settings();

    this.accountLogged$.subscribe({
      next: me => me ? this._checkToken = this.checkToken(60000) : this._checkToken?.unsubscribe()
    });
  }

  get token(): string | null {
    const _token = this.sessionStorage.getItem('token');
    return _token || null;
  }

  set token(token: string | undefined) {
    this.sessionStorage.setItem('token', token || '');
  }

  get realityCheck(): number | null {
    const check = this.sessionStorage.getItem('realityCheck');
    return Number(check) ?? null;
  }

  set realityCheck(check: number | null) {
    if (check) {
      this.sessionStorage.setItem('realityCheck', check.toString());
    }
  }

  isLogged(): boolean {
    if (this.accountLogged$.getValue()) {
      return true;
    }

    const sessionId = this.token;
    if (!sessionId) {
      return false;
    }

    this.getAuthentication().subscribe({
      next: u => {
        if (u?.passwordExpired) {
          return this.securityRedir();
        }

        if (this.config.features?.documentNeeded && !u?.documents?.length) {
          return this.documentsRedir();
        }
      }
    });

    return false;
  }

  reset() {
    this.currentProfile.set(undefined);
    this.accountLogged$.next(undefined);
    this.sessionStorage.removeItem('token');
  }

  login(credentials: ILoginCredentials): Observable<ILoginResponse> {

    return this.http.post<ILoginResponse>(`${this.url}/auth/login`, credentials).pipe(
      tap(value => {
        if (value.token) {
          this.token = value.token;
          //this.catalog.currentCurrency = value.me.currencies[0];
          this.accountLogged$.next(value.me);
          this.currentProfile.set(value.me);

          if (value.me?.passwordExpired) {
            return this.securityRedir();
          }

          if (this.config.features?.documentNeeded && !value.me?.documents?.length) {
            return this.documentsRedir();
          }

          //if (value.me?.typeId != AccountTypeId.Operator) {
          if (this.config.features?.responsibleGaming?.realityChecker) {
            this.realityCheck = new Date().getTime();
          }
          //}

        }

      })
    );
  }

  loginToken(token: string): Observable<ILoginResponse | undefined> {
    return this.http.post<ILoginResponse>(`${this.url}/auth/login-token`, { token }).pipe(
      tap(loginResponse => {
        this.token = loginResponse.token;
        this.accountLogged$.next(loginResponse.me);
        this.currentProfile.set(loginResponse.me);
      }),
      catchError(error => {
        this.reset();
        // this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'});
        return throwError(error);
      }));
  }

  /* refreshMe(partial: Partial<IMe>) {
     const me = this.accountLogged$.getValue();
     const refresh = Object.assign(me, partial);
     this.accountLogged$.next(refresh);
   }*/

  //todo: check return object
  logout(reason?: string, redir = '') {

    if (reason && reason === 'forced') {
      // this.snackBar.open(this.translate.instant(message), this.translate.instant('close'), {panelClass: 'error'});
    }

    if (this.accountLogged$.getValue()?.typeId != AccountTypeId.Operator) {
      if (this.config.features?.responsibleGaming?.realityChecker) {
        this.sessionStorage.removeItem('realityCheck');
      }
    }

    this._checkToken?.unsubscribe();

    if (this.isValid()) { // cannot call logout if token not in place
      return this.http.post(`${this.url}/auth/logout`, {}).pipe(tap(() => {
        this.reset();
        let nav = `${this.settings.languageCode}`;
        if (redir) {
          nav = `${this.settings.languageCode}/${redir}`;
        }
        void this.router.navigateByUrl(nav);
      }));
    } else {
      this.reset();
    }

    return of();
  }

  isValid(): boolean {
    if (!this.token) {
      return false;
    }

    const tokenData = JSON.parse(this.window.atob(this.token.split('.')[1]));
    const unixTs = Math.floor(new Date().getTime() / 1000);
    return unixTs < tokenData.exp;
  }

  register(data: RegistrationBaseRequest): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(`${this.url}/auth/register`, data);
  }

  getAuthentication(): Observable<IMe | undefined> {

    if (this.accountLogged$.getValue()) {
      return of(this.accountLogged$.getValue());
    }

    return this.http.get<IMe>(`${this.url}/me`).pipe(
      tap(me => {
        //this.catalog.currentCurrency = me.currencies[0]; // in order to set the defaultCurrency when the browser is refreshed
        this.accountLogged$.next(me);
        this.currentProfile.set(me);
      }),
      catchError(err => {
        this.currentProfile.set(undefined);
        this.accountLogged$.next(undefined);
        this.logout();
        return throwError(err);
      })
    );
  }


  private securityRedir() {
    void this.router.navigate([`/${this.settings.languageCode}/me/overview/security`]);
  }

  private documentsRedir() {
    void this.router.navigate([`/${this.settings.languageCode}/me/overview/documents`]);
  }

  private checkToken(period: number) {
    return interval(period).subscribe({
      next: () => {
        this.http.get<CheckToken>(`${this.url}/auth/check-token`).subscribe({
          next: data => {
            // const now = Math.floor(Date.now() / 1000); // current epoch time in seconds
            // const lastUpdate = Math.floor(new Date(data.lastUpdate).getTime()/1000) // epoch time in seconds
            if (data.isClosed) {
              this.logout('auto_logout', 'auto-logout');
            }
          },

          error: () => this._checkToken.unsubscribe()
        });
      }
    });
  }
}
